import tracking, { TrackingCardPosition } from 'src/tracking'

import { Recipe } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { CardVariant } from '../../components/molecules/ContentCard/styled'
import { RecipeCardProps } from '../../components/molecules/RecipeCard'
import { CardContentType } from '../../components/molecules/ContentCard'

export const transformRecipeTinyCard = (
  data: Recipe,
  blockName?: string
): RecipeCardProps => ({
  type: CardContentType.Recipe,
  title: data?.title,
  ...(data?.slug && {
    linkProps: {
      href: Router.getRouteUrl(routes.recipe, { slug: data?.slug }),
      onClick: () =>
        tracking.cards.block(
          blockName,
          CardContentType.Recipe,
          data?.title,
          TrackingCardPosition.CONTENT
        ),
    },
  }),
  imageProps: {
    maxWidth: 400,
    withHD: true,
    withLazyLoading: true,
    images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
    alt: data?.title ?? data?.thumbnail01?.alt ?? '',
  },
  duration: data?.tagsPreparationTime?.[0]?.name,
  brandName: data?.brand?.name,
  variant: CardVariant.Tiny,
})
