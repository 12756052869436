import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import type { BreadcrumbProps } from '../../components/molecules/Breadcrumb'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'
import { app } from '../../configuration'

const useBreadcrumb = (
  entries?: BreadcrumbProps['entries'],
  deps?: any[]
): BreadcrumbProps => {
  const { t } = useTranslation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoEntries = useMemo(() => entries, deps ?? [entries])

  return useMemo(
    () => ({
      entries: memoEntries
        ? [
            {
              label: t('breadcrumb_home'),
              href: Router.getRouteUrl(routes.page, { slug: '/' }),
            },
            ...memoEntries.filter((obj) => Object.keys(obj).length !== 0),
          ]
        : [],
      jsonLdBasePath: app.APP_URL,
    }),
    [t, memoEntries]
  )
}

export default useBreadcrumb
