import React, { FC, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { RelatedRangeProps } from '../RelatedRange';
import { ActionButtonVariant } from '../../atoms/ActionButton/styled';
import { Icons } from '../../atoms/Icon';
import { EcoPushProps } from '../../atoms/EcoPush';
import * as SC from './styled';
export type RelatedProductsBlockProps = MinimalSeoBlock & {
  className?: string;
  title?: string;
  rangeTitle?: string;
  rangeBody?: string;
  ranges: RelatedRangeProps[];
  ecopush?: EcoPushProps;
  isOpen?: boolean;
  defaultNumberOnMobile?: number;
  defaultNumberOnDesktop?: number;
  texts?: {
    seeMoreProducts?: string;
    seeLessProducts?: string;
  };
};
const RelatedProductsBlock: FC<RelatedProductsBlockProps> = props => {
  const {
    className,
    htmlTag,
    title,
    rangeTitle,
    rangeBody,
    htmlTitleTag,
    ranges,
    ecopush,
    isOpen,
    defaultNumberOnMobile = 1,
    defaultNumberOnDesktop = 3,
    texts
  } = props;
  const [stateOpen, setStateOpen] = useState(false);
  useEffect(() => {
    setStateOpen(isOpen ?? false);
  }, [isOpen]);
  const handleToggle = useCallback(() => {
    setStateOpen(!stateOpen);
  }, [stateOpen]);
  return <SC.RelatedProductsBlock className={cx('RelatedProductsBlock', className)} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      {rangeTitle && <SC.RangeTitle>{rangeTitle}</SC.RangeTitle>}
      {rangeBody && <SC.RangeBody>{rangeBody}</SC.RangeBody>}
      <SC.Ranges>
        {ranges?.map((range, index) => <SC.Range key={`RelatedProductsBlock-range-${index}`} {...range} $defaultNumberOnMobile={defaultNumberOnMobile} $defaultNumberOnDesktop={defaultNumberOnDesktop} $isOpen={stateOpen} />)}
      </SC.Ranges>
      <SC.ActionMore onClick={handleToggle} label={stateOpen ? texts?.seeLessProducts : texts?.seeMoreProducts} variant={ActionButtonVariant.secondary} iconPosition="right" iconProps={{
      icon: stateOpen ? Icons.circleChevronUp : Icons.circleChevronDown
    }} $defaultNumberOnMobile={defaultNumberOnMobile} $defaultNumberOnDesktop={defaultNumberOnDesktop} $length={ranges?.length} />
      {ecopush && <SC.Push {...ecopush} />}
    </SC.RelatedProductsBlock>;
};
export default RelatedProductsBlock;